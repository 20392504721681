<template>
	<div class="wp-editor">
		<div class="uu-header">
			<div class="header-wrap">
				<a class="logo"><img src="@/assets/images/seller/decorate-logo-black.png" alt=""></a>
				<span>{{$t('seller.pageEditor.pagecurr')}}{{decorateInfo.name}}</span>
			</div>
			<ul class="action">
				<li><el-button @click="handleSave" size="mini">{{$t('seller.actions.save')}}</el-button></li>
				<li>
					<el-button type="primary" @click="handlePublish" size="mini">{{$t('seller.actions.publish')}}</el-button>
				</li>
			</ul>
		</div>
		<div class="uu-content">
			<div class="side-menu"></div>
			<div class="content-wrap">
				<!-- <div class="module-component"></div> -->
				<div class="previewer-component">
					<el-scrollbar>
						<div class="previewer-wrap">
							
							<div class="item">
								<div class="item-mask" :class="checkItem==1?'active':''" @click="handleItemChange(1)"></div>
								<div class="item-title">{{$t('seller.formItem.shopTitle')}}</div>
								<div class="item-main">
									<div class="wp-store-head">
										<div class="pic"><img :src="storeInfo.store_logo" @error="imageErrorShop" alt=""></div>
										<div class="head-main">
											<div class="name">{{storeInfo.store_name}}</div>
											<div class="tabs"></div>
											<div class="btn">{{$t('seller.pageEditor.follow')}}</div>
											<div class="text"><span>{{$t('seller.pageEditor.fansNum')}}{{storeInfo.count_fans}}</span></div>
										</div>
									</div>
								</div>
								<div class="item-editor" v-show="checkItem==1">
									<div class="editor-title">
										<div class="editor-title-con">
											<div class="name">{{$t('seller.formItem.shopTitle')}}</div>
										</div>
										<i class="el-icon-close" @click="handleItemChange(0)"></i>
									</div>
									<div class="editor-main">
										<div class="editor-item">
											<div class="item-label">
												<el-upload
													action="#"
													:show-file-list="false"
													v-loading="loadingUplaod"
													:http-request="httpLogoRequest">
													<a href="javascript:void(0);">{{$t('seller.actions.uploadLogo')}}</a>
												</el-upload>
											</div>
											<div class="item-block"></div>
										</div>
									</div>
									<div class="editor-footer">
										<el-button size="mini" @click="handleSaveLogo">{{$t('seller.actions.save')}}</el-button>
									</div>
								</div>
							</div>
							
							<div class="item">
								<div class="item-mask" :class="checkItem==2?'active':''" @click="handleItemChange(2)"></div>
								<div class="item-title">{{$t('seller.formItem.shopIntroduce')}}</div>
								<div class="item-main" v-if="previewerList.introduce.thumb && previewerList.introduce.is_off==1">
									<div class="item-view">
										<div class="item-view-title">{{$t('seller.formItem.shopIntroduce')}}</div>
										<div class="item-view-main">
											<video :src="previewerList.introduce.thumb"></video>
										</div>
									</div>
								</div>
								<div class="item-main" v-else>
									<img src="@/assets/images/seller/decorate-steps-750-476.png" alt="" width="100%">
								</div>
								<div class="item-editor" v-show="checkItem==2">
									<div class="editor-title">
										<div class="editor-title-con">
											<div class="name">{{$t('seller.formItem.shopIntroduce')}}</div>
										</div>
										<i class="el-icon-close" @click="handleItemChange(0)"></i>
									</div>
									<div class="editor-main">
										<div class="editor-item">
											<div class="item-label">{{$t('seller.formItem.decorateSwitch')}}</div>
											<div class="item-block"><el-switch v-model="previewerList.introduce.is_off" active-value="1" inactive-value="0"></el-switch></div>
										</div>
										<div class="editor-item" v-if="previewerList.introduce.is_off==1">
											<div class="item-con">
												<div class="con-title">{{$t('seller.actions.addVideo')}}</div>
												<div class="con-desc">{{$t('seller.pageEditor.tips1')}}<a href="javscsript:void(0);">{{$t('seller.actions.learnMore')}}</a></div>
												<el-upload
													action="#"
													v-loading="loadingUplaod"
													accept="video/*"
													:show-file-list="false"
													:http-request="httpRequestIntroduceUpload">
													<div class="con-uplaod" v-if="previewerList.introduce.thumb"><video :src="previewerList.introduce.thumb"></video></div>
													<div class="con-uplaod" v-else><i class="el-icon-plus"></i>{{$t('seller.actions.addVideo')}}</div>
												</el-upload>
											</div>
										</div>
									</div>
									<div class="editor-footer">
										<el-button @click="handleSave('introduce')" size="mini" v-if="previewerList.introduce.is_off==1">{{$t('seller.actions.save')}}</el-button>
										<el-button size="mini" v-else>{{$t('seller.actions.save')}}</el-button>
									</div>
								</div>
							</div>
							
							<div class="item">
								<div class="item-mask" :class="checkItem==3?'active':''" @click="handleItemChange(3)"></div>
								<div class="item-title">{{$t('seller.formItem.shopStory')}}</div>
								<div class="item-main"><img src="@/assets/images/seller/decorate-steps-750-570.png" alt="" width="100%"></div>
								<div class="item-editor" v-show="checkItem==3">
									<div class="editor-title">
										<div class="editor-title-con">
											<div class="name">{{$t('seller.formItem.shopStory')}}</div>
										</div>
										<i class="el-icon-close" @click="handleItemChange(0)"></i>
									</div>
									<div class="editor-main">
										<div class="editor-item">
											<div class="item-label">{{$t('seller.formItem.decorateSwitch')}}</div>
											<div class="item-block"><el-switch v-model="previewerList.story.is_off" active-value="1" inactive-value="0"></el-switch></div>
										</div>
										<div class="editor-item" v-if="previewerList.story.is_off==1">
											<div class="item-con">
												<div class="con-title">{{$t('seller.actions.addVideo')}}</div>
												<div class="con-desc">{{$t('seller.pageEditor.tips2')}}<a href="javscsript:void(0);">{{$t('seller.actions.learnMore')}}</a></div>
												<div class="con-row" v-for="item,index in previewerList.story.thumb" :key="index">
													<div class="row-move">
														<i class="el-icon-top disabled" v-if="index==0"></i>
														<i class="el-icon-top" @click="handleItemUp('desc',index)" v-else></i>
														<i class="el-icon-bottom disabled" v-if="index==previewerList.story.thumb.length-1"></i>
														<i class="el-icon-bottom" @click="handleItemDown('story',index)" v-else></i>
													</div>
													<div class="row-main">
														<el-upload
															action="#"
															v-loading="loadingUplaod && loadingIndex==index"
															accept="video/*"
															:show-file-list="false"
															:http-request="function(data){httpRequestStoryUpload(data,index)}">
															<div class="row-upload" v-if="item.path"><video :src="item.path"></video></div>
															<div class="row-upload" v-else><i class="el-icon-plus"></i></div>
														</el-upload>
														<div class="row-input">
															<div class="mb12"><el-input v-model="item.name" size="mini" maxlength="14" show-word-limit :placeholder="$t('seller.placeholder.videoTitle')"></el-input></div>
															<div><el-input v-model="item.link" size="mini" :placeholder="$t('seller.placeholder.selectLink')">
																<i slot="suffix" class="el-input__icon el-icon-link"></i>
															</el-input></div>
														</div>
													</div>
													<div class="row-del" v-if="previewerList.story.thumb.length>1"><i @click="handleItemDel('story',index)" class="el-icon-delete"></i></div>
													<div class="row-del" v-else><i class="el-icon-delete disabled"></i></div>
												</div>
												<div class="con-add disabled" v-if="previewerList.story.thumb.length==5"><i class="el-icon-plus"></i>{{$t('seller.actions.add')}}{{previewerList.story.thumb.length}}/5</div>
												<div class="con-add" @click="handleItemAdd('story')" v-else><i class="el-icon-plus"></i>{{$t('seller.actions.add')}}{{previewerList.story.thumb.length}}/5</div>
											</div>
										</div>
									</div>
									<div class="editor-footer">
										<el-button @click="handleSave('story')" size="mini" v-if="previewerList.story.is_off==1">{{$t('seller.actions.save')}}</el-button>
										<el-button size="mini" v-else>{{$t('seller.actions.save')}}</el-button>
									</div>
								</div>
							</div>
							
							<div class="item">
								<div class="item-mask" :class="checkItem==4?'active':''" @click="handleItemChange(4)"></div>
								<div class="item-title">{{$t('seller.formItem.shopDesc')}}</div>
								<div class="item-main"><img src="@/assets/images/seller/decorate-steps-750-376-2.png" alt="" width="100%"></div>
								<div class="item-editor" v-show="checkItem==4">
									<div class="editor-title">
										<div class="editor-title-con">
											<div class="name">{{$t('seller.formItem.shopTitle')}}</div>
										</div>
										<i class="el-icon-close" @click="handleItemChange(0)"></i>
									</div>
									<div class="editor-main">
										<div class="editor-item">
											<div class="item-label">{{$t('seller.formItem.decorateSwitch')}}</div>
											<div class="item-block"><el-switch v-model="previewerList.explain.is_off" active-value="1" inactive-value="0"></el-switch></div>
										</div>
										<div class="editor-item" v-if="previewerList.explain.is_off==1">
											<div class="item-con">
												<div class="con-title">{{$t('seller.actions.addLink')}}</div>
												<div class="con-desc">{{$t('seller.pageEditor.tips3')}}<a href="javscsript:void(0);">{{$t('seller.actions.learnMore')}}</a></div>
												<div class="con-row" v-for="(item,index) in previewerList.explain.thumb" :key="index">
													<div class="row-move">
														<i class="el-icon-top disabled" v-if="index==0"></i>
														<i class="el-icon-top" @click="handleItemUp('explain',index)" v-else></i>
														<i class="el-icon-bottom disabled" v-if="index==previewerList.explain.thumb.length-1"></i>
														<i class="el-icon-bottom" @click="handleItemDown('explain',index)" v-else></i>
													</div>
													<div class="row-main">
														<div class="row-input">
															<div class="mb12"><el-input v-model="item.name" size="mini" maxlength="14" show-word-limit :placeholder="$t('seller.placeholder.videoTitle')"></el-input></div>
															<div><el-input v-model="item.link" size="mini" :placeholder="$t('seller.placeholder.selectLink')">
																<i slot="suffix" class="el-input__icon el-icon-link"></i>
															</el-input></div>
														</div>
													</div>
													<div class="row-del" v-if="previewerList.explain.thumb.length>1"><i @click="handleItemDel('explain',index)" class="el-icon-delete"></i></div>
													<div class="row-del" v-else><i class="el-icon-delete disabled"></i></div>
												</div>
												<div class="con-add disabled" v-if="previewerList.explain.thumb.length==5"><i class="el-icon-plus"></i>{{$t('seller.actions.add')}}{{previewerList.explain.thumb.length}}/5</div>
												<div class="con-add" @click="handleItemAdd('explain')" v-else><i class="el-icon-plus"></i>{{$t('seller.actions.add')}}{{previewerList.explain.thumb.length}}/5</div>
											</div>
										</div>
									</div>
									<div class="editor-footer">
										<el-button @click="handleSave('explain')" size="mini" v-if="previewerList.explain.is_off==1">{{$t('seller.actions.save')}}</el-button>
										<el-button size="mini" v-else>{{$t('seller.actions.save')}}</el-button>
									</div>
								</div>
							</div>
							
							<div class="item">
								<div class="item-mask" :class="checkItem==5?'active':''" @click="handleItemChange(5)"></div>
								<div class="item-title">{{$t('seller.formItem.shopInfo')}}</div>
								<div class="item-main">
									<div class="wp-store-info">
										<div class="info-title">{{$t('seller.formItem.shopInfo')}}</div>
										<div class="info-item">
											<div class="item-label">{{$t('seller.pageEditor.userName')}}</div>
											<div class="item-block"><span>{{userInfo.userName}}</span></div>
										</div>
										<div class="info-item">
											<div class="item-label">{{$t('seller.pageEditor.regionName')}}</div>
											<div class="item-block"><span>{{storeInfo.region_name}}</span></div>
										</div>
										<div class="info-item">
											<div class="item-label">{{$t('seller.pageEditor.qualifier')}}</div>
											<div class="item-block">
												<img class="icon" src="@/assets/images/seller/decorate-steps-32-32.png" alt="">
												<span>{{$t('seller.pageEditor.agreement')}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="item-editor" v-show="checkItem==5">
									<div class="editor-title">
										<div class="editor-title-con">
											<div class="name">{{$t('seller.formItem.shopInfo')}}</div>
										</div>
										<i class="el-icon-close" @click="handleItemChange(0)"></i>
									</div>
									<div class="editor-main">
										<el-empty :image="require('@/assets/images/seller/empty-130-103.png')" :description="$t('seller.empty.unsupported')"></el-empty>
									</div>
									<div class="editor-footer">
										<el-button size="mini">{{$t('seller.actions.save')}}</el-button>
									</div>
								</div>
							</div>
							
						</div>
					</el-scrollbar>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { normalOSSUpload } from '@/utils/upload'
	import { getInfo } from '@/api/seller/index'
	import { fetchMobile, updateStoreLogo, updateMobile, releaseMobile } from '@/api/seller/decorate'
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				storeInfo: {
					store_logo: '',
					store_name: '',
					region_name: '',
				},
				decorateInfo: {},
				checkItem: 0,
				previewerList: {
					introduce: {
						is_off: '0',
						thumb: '',
					},
					story: {
						is_off: '0',
						thumb: [
							{ path: '', name: '', link: '' },
						],
					},
					explain: {
						is_off: '0',
						thumb: [
							{ name: '', link: '' },
						],
					}
				},
				loadingIndex: -1,
				loadingUplaod: false,
			}
		},
		created() {
			this.getStoreInfo();
			this.getDecorateInfo();
		},
		methods: {
			imageErrorShop(e){
				e.target.src = this.$imageErrorLogo
			},
			getStoreInfo(){
				getInfo({uuid: this.$store.getters.uuid}).then(response => {
					this.storeInfo = response.data;
				})
			},
			getDecorateInfo(){
				fetchMobile({wap_store_page_id: this.$route.params.id}).then(response => {
					const { data } = response;
					this.decorateInfo = data;
					this.previewerList = data.content?JSON.parse(data.content):this.previewerList;
				})
			},
			handleItemChange(type){
				this.checkItem = type;
			},
			handleItemUp(type,index){
				if(index>0){
					let list = this.previewerList[type].thumb;
					this.previewerList[type].thumb[index]=list.splice(index-1,1,list[index])[0];
				}
			},
			handleItemDown(type,index){
				if(index!==this.previewerList[type].thumb.length-1) {
					let list = this.previewerList[type].thumb;
					this.previewerList[type].thumb[index]=list.splice(index+1,1,list[index])[0];
				}
			},
			handleItemDel(type,index){
				this.previewerList[type].thumb.splice(index,1);
			},
			handleItemAdd(type){
				if(type==='story' && this.previewerList.story.thumb.length<5){
					this.previewerList.story.thumb.push({ path: '', name: '', link: '' });
				}else if(type==='explain' && this.previewerList.explain.thumb.length<5){
					this.previewerList.explain.thumb.push({ name: '', link: '' });
				}
			},
			async httpLogoRequest(value){
				const { file } = value;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 3;
				if (!istype) {
					this.$message.error(this.$t('seller.upload.imageType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize3M'));
					return false;
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'other');
				if(code==200){
					this.storeInfo.store_logo = data.url;
				}
				this.loadingUplaod = false;
			},
			async httpRequestIntroduceUpload(value){
				const { file } = value;
				const istype = file.type.indexOf('video');
				const isSize = file.size / 1024 / 1024 < 5;
				if (istype<0) {
					this.$message.error(this.$t('seller.upload.videoType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.videoSize5M'));
					return false;
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'video');
				if(code==200){
					this.previewerList.introduce.thumb = data.url;
				}
				this.loadingUplaod = false;
			},
			async httpRequestStoryUpload(value,index){
				const { file } = value;
				const istype = file.type.indexOf('video');
				const isSize = file.size / 1024 / 1024 < 5;
				if (istype<0) {
					this.$message.error(this.$t('seller.upload.videoType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.videoSize5M'));
					return false;
				}
				this.loadingIndex = index;
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'video');
				if(code==200){
					this.previewerList.story.thumb[index].path = data.url;
				}
				this.loadingUplaod = false;
			},
			handleSaveLogo(){
				updateStoreLogo({store_logo: this.storeInfo.store_logo}).then(response => {
					this.checkItem = 0;
					this.$message.success(this.$t('seller.successMsg.save'));
				})
			},
			handleSave(type){
				let that = this;
				let options = {
					introduce: {
						is_off: '0',
						thumb: '',
					},
					story: {
						is_off: '0',
						thumb: [
							{ path: '', name: '', link: '' },
						],
					},
					explain: {
						is_off: '0',
						thumb: [
							{ name: '', link: '' },
						],
					}
				}
				options = that.decorateInfo.content?JSON.parse(that.decorateInfo.content):options;
				if(type==='introduce'){
					if(that.previewerList.introduce.is_off==1 && !that.previewerList.introduce.thumb){
						that.$message.error(that.$t('seller.errorMsg.complete'));
						return false;
					}
					options.introduce = that.previewerList.introduce;
				}if(type==='story'){
					if(that.previewerList.story.is_off==1){
						let flag = 0;
						that.previewerList.story.thumb.forEach(item=>{
							if(!item.path || !item.name || !item.link){
								flag = 1
							}
						})
						if(flag==1){
							that.$message.error(that.$t('seller.errorMsg.complete'));
							return false;
						}
					}
					options.story = that.previewerList.story;
				}if(type==='explain'){
					if(that.previewerList.explain.is_off==1){
						let flag = 0;
						that.previewerList.explain.thumb.forEach(item=>{
							if(!item.name || !item.link){
								flag = 1
							}
						})
						if(flag==1){
							that.$message.error(that.$t('seller.errorMsg.complete'));
							return false;
						}
					}
					options.explain = that.previewerList.explain;
				}else{
					options = that.previewerList;
				}
				that.decorateInfo.content = JSON.stringify(options);
				updateMobile(that.decorateInfo).then(response => {
					that.checkItem = 0;
					that.$message.success(that.$t('seller.successMsg.save'));
				})
			},
			handlePublish(){
				let that = this;
				that.$confirm(that.$t('seller.confirm.publishPage'),that.$t('seller.confirm.title'),{
					confirmButtonText: that.$t('seller.actions.confirm'),
					cancelButtonText: that.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					let params = {
						wap_store_page_id: that.decorateInfo.wap_store_page_id
					}
					releaseMobile(params).then(response => {
						that.$message.success(that.$t('seller.successMsg.publish'))
					})
				}).catch(() => {
					that.$message.error(that.$t('seller.cancelMsg.publish'));
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wp-editor {
		min-width: 1200px;
		height: 100%;
		background-color: #e6e9ed;
		.uu-header {
			height: 60px;
			padding: 0 28px;
			z-index: 3;
			position: relative;
			background-color: #FFFFFF;
			border-bottom: 1px solid #e6e9ed;
		
			.header-wrap {
				float: left;
				padding: 12px 0;
				height: 36px;
				line-height: 36px;
		
				.logo {
					color: #333333;
					font-size: 24px;
					height: 36px;
					line-height: 36px;
					display: inline-block;
					vertical-align: middle;
					img {
						height: 100%;
					}
				}
		
				span {
					display: inline-block;
					vertical-align: middle;
					margin-left: 20px;
				}
			}
		
			.action {
				float: right;
				padding: 16px 0;
				white-space: nowrap;
		
				li {
					font-size: 14px;
					margin-left: 20px;
					display: inline-block;
				}
			}
		}
		.uu-content {
			height: calc(100% - 61px);
			::v-deep .el-scrollbar {
				height: 100%;
			
				.el-scrollbar__wrap {
					overflow-x: hidden;
				}
			}
			.content-wrap {
				height: 100%;
				display: flex;
				flex: 1;
				.module-component  {
					width: 250px;
					height: 100%;
					box-sizing: border-box;
					border-right: 1px solid #e6e9ed;
					background-color: #FFFFFF;
					box-shadow: 0 0 5px -2px rgba(0, 0, 0, .2);
				}
				.previewer-component {
					flex: 1;
					height: 100%;
					display: block;
					::v-deep .el-scrollbar__view {
						min-height: 100%;
						padding: 20px 0;
						box-sizing: border-box;
					}
					.previewer-wrap {
						width: 375px;
						min-height: 100%;
						margin: 0 auto;
						box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
						background-color: #eeeeee;
						.item {
							position: relative;
							margin-bottom: 14px;
							.item-title {
								top: 0;
								right: -40px;
								width: 18px;
								line-height: 18px;
								border-radius: 14px;
								position: absolute;
								padding: 10px 6px;
								font-size: 14px;
								color: #a2a2a2;
								text-align: center;
								background-color: rgba(255,255,255,.5);
							}
							.item-mask {
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								z-index: 9;
								position: absolute;
								cursor: pointer;
							}
							.active {
								box-shadow: 0 0 0 3px $--uucolor-primary inset;
							}
							.item-view {
								padding: 0 13px 12px;
								.item-view-title {
									height: 38px;
									line-height: 38px;
									color: #666666;
									font-size: 16px;
									margin-left: 14px;
								}
								.item-view-main {
									height: 188px;
									overflow: hidden;
									border-radius: 12px;
									background-color: #fcfcfc;
									video {
										width: 100%;
										height: 100%;
									}
								}
							}
							.item-editor {
								top: 0;
								left: calc(100% + 55px);
								position: absolute;
								width: 330px;
								background-color: #FFFFFF;
								padding: 0 15px 10px;
								box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
								.editor-title {
									display: flex;
									height: 49px;
									align-items: center;
									border-bottom: 1px solid #CCCCCC;
									.editor-title-con {
										flex: 1;
										width: 0;
										color: #999999;
										font-size: 14px;
									}
									.el-icon-close {
										cursor: pointer;
										font-size: 18px;
										padding-left: 14px;
										border-left: 1px solid #E6E6E6;
										margin-left: 14px;
									}
								}
								.editor-main {
									border-bottom: 1px solid #CCCCCC;
									::v-deep .el-empty {
										padding: 20px 0;
										.el-empty__image {
											width: 110px;
										}
									}
									.editor-item {
										margin: 10px 0;
										display: flex;
										align-items: center;
										line-height: 22px;
										.item-label {
											flex: 1;
											width: 0;
											a {
												color: $--seller-primary;
											}
										}
										.item-block {
											margin-left: 5px;
										}
										.item-con {
											width: 100%;
											display: block;
											::v-deep .el-upload {
												display: block;
											}
											.con-title {
												line-height: 20px;
												margin-bottom: 8px;
											}
											.con-desc {
												color: #999999;
												font-size: 14px;
												line-height: 20px;
												margin-bottom: 5px;
												a {
													color: $--seller-primary;
												}
											}
											.con-row {
												display: flex;
												margin-bottom: 10px;
												background-color: #F9F9F9;
												.row-move {
													width: 25px;
													display: flex;
													align-items: center;
													flex-direction: column;
													justify-content: center;
													color: #999999;
													i {
														font-size: 14px;
														margin: 5px 0;
														cursor: pointer;
														font-weight: bold;
													}
													.disabled {
														color: #c9c9c9;
														cursor: not-allowed;
													}
												}
												.row-main {
													flex: 1;
													width: 0;
													padding: 10px 0;
													display: flex;
													.row-upload {
														width: 70px;
														height: 70px;
														line-height: 70px;
														text-align: center;
														background-color: #f4f4f4;
														video {
															width: 100%;
															height: 100%;
														}
													}
													.row-input {
														flex: 1;
														width: 0;
														padding-left: 15px;
														.mb12 {
															margin-bottom: 12px;
														}
														::v-deep .el-input__suffix {
															cursor: pointer;
														}
													}
												}
												.row-del {
													width: 25px;
													display: flex;
													align-items: center;
													justify-content: center;
													i {
														color: #999999;
														font-size: 14px;
														margin: 5px 0;
														cursor: pointer;
														font-weight: bold;
													}
													.disabled {
														color: #c9c9c9;
														cursor: not-allowed;
													}
												}
											}
											.con-uplaod,.con-add {
												display: flex;
												align-items: center;
												justify-content: center;
												background-color: #F4F4F4;
												font-size: 14px;
												cursor: pointer;
											}
											.con-uplaod {
												height: 150px;
												img {
													width: 100%;
													height: 100%;
													object-fit: cover;
												}
												video {
													width: 100%;
													height: 100%;
												}
											}
											.con-add {
												height: 40px;
												margin-top: 16px;
											}
											.con-add.disabled {
												color: #c9c9c9;
												cursor: not-allowed;
											}
										}
									}
								}
								.editor-footer {
									padding-top: 10px;
									text-align: right;
								}
							}
						}
						.wp-store-head {
							padding: 15px 12px;
							background-color: #FFFFFF;
							display: flex;
							.pic {
								width: 60px;
								height: 60px;
								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}
							.head-main {
								flex: 1;
								width: 0;
								padding-left: 12px;
								.name {
									height: 20px;
									line-height: 20px;
									font-size: 14px;
									margin-bottom: 6px;
								}
								.tabs {
									height: 20px;
								}
								.text {
									height: 16px;
									line-height: 16px;
									color: #999999;
									font-size: 12px;
									padding-top: 8px;
								}
								.btn {
									width: 60px;
									height: 24px;
									line-height: 24px;
									border-radius: 12px;
									float: right;
									text-align: center;
									color: #FFFFFF;
									background: linear-gradient(to right, rgb(255, 153, 0), rgb(255, 86, 10));
								}
							}
						}
						.wp-store-info {
							background-color: #FFFFFF;
							.info-title {
								padding: 0 12px;
								height: 40px;
								line-height: 40px;
								font-size: 16px;
								color: #999999;
							}
							.info-item {
								height: 40px;
								line-height: 40px;
								padding: 0 12px;
								display: flex;
								div {
									width: 50%;
									flex-shrink: 0;
								}
								.item-label {
									color: #333333;
									font-size: 14px;
								}
								.item-block {
									font-size: 12px;
									text-align: right;
									white-space: nowrap;
									display: flex;
									align-items: center;
									justify-content: right;
									.icon {
										width: 20px;
										height: 20px;
										margin-right: 12px;
										display: inline-block;
										vertical-align: middle;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
